import { QRCodeCanvas } from 'qrcode.react';
import DraggableModal from '../../shared/components/DraggableModal';
import { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';

const LoginQrCodeModal = ({ showLoginQrModal, setShowLoginQrModal, classDetails }) => {
  const contentRef = useRef(null);

  // 한 페이지에 표시할 카드 개수
  const cardsPerPage = 9;

  // 데이터를 페이지별로 나누기
  const paginatedStudents = [];
  for (let i = 0; i < classDetails?.students?.length; i += cardsPerPage) {
    paginatedStudents.push(classDetails.students.slice(i, i + cardsPerPage));
  }

  const handlePrint = useReactToPrint({
    onBeforeGetContent: () => contentRef.current.classList.remove('hidden'),
    content: () => contentRef.current,
    onAfterPrint: () => contentRef.current.classList.add('hidden'),
  });

  return (
    <DraggableModal show={showLoginQrModal} setShow={setShowLoginQrModal} title={'로그인 QR 목록'}>
      <div className={'w-[80vw] p-[20px]'}>
        <div className={'flex justify-end'}>
          <div className="cursor-pointer p-[10px] rounded-lg hover:bg-gray-100" onClick={() => handlePrint()}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-10">
              <path strokeLinecap="round" strokeLinejoin="round"
                    d="M6.72 13.829c-.24.03-.48.062-.72.096m.72-.096a42.415 42.415 0 0 1 10.56 0m-10.56 0L6.34 18m10.94-4.171c.24.03.48.062.72.096m-.72-.096L17.66 18m0 0 .229 2.523a1.125 1.125 0 0 1-1.12 1.227H7.231c-.662 0-1.18-.568-1.12-1.227L6.34 18m11.318 0h1.091A2.25 2.25 0 0 0 21 15.75V9.456c0-1.081-.768-2.015-1.837-2.175a48.055 48.055 0 0 0-1.913-.247M6.34 18H5.25A2.25 2.25 0 0 1 3 15.75V9.456c0-1.081.768-2.015 1.837-2.175a48.041 48.041 0 0 1 1.913-.247m10.5 0a48.536 48.536 0 0 0-10.5 0m10.5 0V3.375c0-.621-.504-1.125-1.125-1.125h-8.25c-.621 0-1.125.504-1.125 1.125v3.659M18 10.5h.008v.008H18V10.5Zm-3 0h.008v.008H15V10.5Z"/>
            </svg>
          </div>
        </div>
        <div className={'mt-[20px]'}>
          <div className={'grid grid-flow-row auto-rows-max gap-4 grid-cols-[repeat(auto-fill,minmax(200px,1fr))] place-items-start'}>
            {classDetails?.students?.map((value, index) => (
              <div key={index} className={'w-[200px] border px-[30px] py-[20px] rounded-[20px] mb-[40px]'}>
                <div className={'flex justify-center font-[pd-sb] text-[20px]'}>AI 아크수학</div>
                <div className={'flex justify-center mt-[20px] font-[pd-sb] text-[18px]'}>{classDetails?.name}</div>
                <div className={'flex justify-center'}>
                  <div>
                    <div className={'w-[100px]'}>번호&nbsp;&nbsp;&nbsp;{value.number}</div>
                    <div className={'w-[100px] truncate'}>이름&nbsp;&nbsp;&nbsp;{value.name}</div>
                  </div>
                </div>
                <div className={'flex justify-center'}>
                  <QRCodeCanvas value={`${process.env.REACT_APP_WEB_URL}/qrcode?type=login&studentId=${value.studentId}`} size={128} bgColor="#ffffff" fgColor="#000000" className={'mt-[20px]'}/>
                </div>
              </div>
            ))}
          </div>
          <div
            className="hidden"
            ref={contentRef}
          >
            {paginatedStudents.map((page, pageIndex) => (
              <div key={pageIndex} className="print-container">
                {page.map((value, index) => (
                  <div key={index} className="print-card">
                    <div className="flex justify-center font-[pd-sb] text-[20px]">AI 아크수학</div>
                    <div className="flex justify-center mt-[20px] font-[pd-sb] text-[18px] w-[170px] truncate">{classDetails?.name}</div>
                    <div className="flex justify-center">
                      <div>
                        <div className={'w-[100px]'}>번호&nbsp;&nbsp;&nbsp;{value.number}</div>
                        <div className={'w-[100px] truncate'}>이름&nbsp;&nbsp;&nbsp;{value.name}</div>
                      </div>
                    </div>
                    <div className="flex justify-center">
                      <QRCodeCanvas
                        value={`${process.env.REACT_APP_WEB_URL}/qrcode?type=login&studentId=${value.studentId}`}
                        size={128}
                        bgColor="#ffffff"
                        fgColor="#000000"
                        className="mt-[20px]"
                      />
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    </DraggableModal>
  );
};

export default LoginQrCodeModal;
