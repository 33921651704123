import { Route, Routes } from 'react-router-dom';
import Lesson from './Lesson';
import Lessons from './Lessons';
import Learning from './Learning';

const Content = ({ startReload, teacher, setTeacher, isPaidVersion, school, lessonInfo, classes, setClasses, classDetails, setClassDetails }) => {
  return (
    <div className={'h-full'}>
      <Routes>
        <Route path={'list'} element={<Lessons
          startReload={startReload}
          teacher={teacher}
          isPaidVersion={isPaidVersion}
          school={school}
          lessonInfo={lessonInfo}
          classes={classes}
          setClasses={setClasses}
          classDetails={classDetails}
          setClassDetails={setClassDetails}
        />}/>
        <Route path={'details'} element={<Lesson
          startReload={startReload}
          teacher={teacher}
          setTeacher={setTeacher}
          isPaidVersion={isPaidVersion}
          school={school}
          lessonInfo={lessonInfo}
          classes={classes}
          setClasses={setClasses}
          classDetails={classDetails}
          setClassDetails={setClassDetails}
        />}/>
        <Route path={'learning'} element={<Learning
          startReload={startReload}
          teacher={teacher}
          lessonInfo={lessonInfo}
          classDetails={classDetails}
        />}/>
        <Route path="*" element={<Lessons
          startReload={startReload}
          teacher={teacher}
          isPaidVersion={isPaidVersion}
          school={school}
          lessonInfo={lessonInfo}
          classes={classes}
          setClasses={setClasses}
          classDetails={classDetails}
          setClassDetails={setClassDetails}
        />}/>
      </Routes>
    </div>
  );
};

export default Content;
