import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { QRCodeCanvas } from 'qrcode.react';

const QrCodePage = () => {
  const [searchParams] = useSearchParams();
  const [loginQrCodeValue, setLoginQrCodeValue] = useState('');
  const [lessonQrCodeValue, setLessonQrCodeValue] = useState('');
  const studentIdParam = searchParams.get('studentId') || '';
  const stageIdParam = searchParams.get('stageId') || '';
  const classLearningIdParam = searchParams.get('classLearningId') || '';

  useEffect(() => {
    setLoginQrCodeValue(`${process.env.REACT_APP_WEB_URL}/qrcode?type=login&studentId=${studentIdParam}`);
    setLessonQrCodeValue(`${process.env.REACT_APP_WEB_URL}/qrcode?type=lesson&stageId=${stageIdParam}&classLearningId=${classLearningIdParam}`);
  }, []);

  return (
    <div className={'flex'}>
      <div>
        <div>로그인</div>
        <QRCodeCanvas value={loginQrCodeValue} size={128} bgColor="#ffffff" fgColor="#000000"/>
      </div>
      <div className={'ml-[100px]'}>
        <div>차시</div>
        <QRCodeCanvas value={lessonQrCodeValue} size={128} bgColor="#ffffff" fgColor="#000000"/>
      </div>
    </div>
  );
};

export default QrCodePage;
