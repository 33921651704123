import { req } from './Network';

// teacher
export const getTeacherDetails = (body) => {
  return req.get(`/v1/teachers/${body.encodedImTeacherLoginId}/details/lessons/${body.imLessonId}`);
};

export const updateTeacher = (body) => {
  return req.put(`/v1/teachers/${body.teacherId}`, body);
};

// class
export const getClassDetails = (body) => {
  return req.get(`/v1/classes/${body.classId}/class-details/lessons/${body.lessonInfoId}`);
};

export const updateClass = (body) => {
  return req.put(`/v1/classes/${body.classId}`, body);
};

// lesson
export const startLessonLearningApp = (body) => {
  return req.post(`/v1/lesson/learning/app`, body);
};

export const assignHomework = (body) => {
  return req.post(`/v1/lesson/homework`, body);
};
