import { useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';

const QrCode = () => {
  const [searchParams] = useSearchParams();
  const typeParam = searchParams.get('type') || '';
  const classIdParam = searchParams.get('classId') || '';
  const lessonInfoIdParam = searchParams.get('lessonInfoId') || '';
  const stageIdParam = searchParams.get('stageId') || '';
  const studentIdParam = searchParams.get('studentId') || '';

  const checkInstallation = () => {
    // console.log('checkInstallation');
    // 환경 확인하기.
    let env;
    if (process.env.REACT_APP_ENV === 'PRODUCTION') {
      env = 'prod';
    } else {
      env = 'dev';
    }
    if (typeParam === 'login') {
      window.location = `arkmath://com.Mathmaster.ArkMath/directLogin?studentId=${studentIdParam}&scheck=${env}`;
    } else if (typeParam === 'lesson') {
      window.location = `arkmath://com.Mathmaster.ArkMath/lesson?classId=${classIdParam}&lessonInfoId=${lessonInfoIdParam}&stageId=${stageIdParam}&scheck=${env}`;
    }

    // 위 경로 실패 시 앱 미설치로 판단한다.
    setTimeout(() => {
      alert('앱 설치가 필요합니다.');
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      let device;
      if (/android/i.test(userAgent)) {
        device = 'android';
      } else if (/iPad|iPhone|iPod|Macintosh/.test(userAgent) && !window.MSStream) {
        device = 'apple';
      } else {
        device = 'other';
      }
      console.log(device);
      window.location = 'https://naver.com';
    }, 1500);
  };

  useEffect(() => {
    checkInstallation();
  }, [typeParam]);
};

export default QrCode;
