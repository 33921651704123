import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { assignHomework, updateTeacher } from '../../network/Endpoint';
import Switch from 'react-switch';
import PasswordUsageModal from './PasswordUsageModal';
import useKeyPress from '../../hooks/useKeyPress';
import LoginQrCodeModal from './LoginQrCodeModal';
import { ReactComponent as Logo } from '../../resources/images/arkmath_logo.svg';
import Class from './Class';
import { checkToast, homeworkTransferToast } from '../../shared/components/Toast';

const Lesson = ({ startReload, teacher, setTeacher, isPaidVersion, school, lessonInfo, classes, setClasses, classDetails, setClassDetails }) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [showPasswordUsageModal, setShowPasswordUsageModal] = useState(false);
  const [showLoginQrModal, setShowLoginQrModal] = useState(false);
  const [lessonStage, setLessonStage] = useState({});

  const lessonStageIdParam = searchParams.get('lessonStageId') || '';

  const startLearning = async () => {
    const searchParams = new URLSearchParams(location.search);
    const queryString = searchParams.toString();
    navigate(`/lesson/learning?${queryString}`);
  };

  const redirectToLmsClassManagement = () => {
    // 모니터의 해상도 크기 가져오기.
    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;

    const newWindow = window.open(
      `${process.env.REACT_APP_ARKMATH_LMS_HOST}/management?teacherId=${teacher.teacherId}`,
      'lms_window',
      `top=0,left=0,width=${screenWidth},height=${screenHeight}`
    );

    // 메시지 이벤트 수신
    const handleMessage = (event) => {
      // 자식창의 도메인 확인 (보안 강화)
      const allowedOrigin = process.env.REACT_APP_ARKMATH_LMS_HOST; // 자식창의 정확한 origin
      if (event.origin === allowedOrigin && event.data === 'reloadParent') {
        window.location.reload(); // 부모창 새로 고침
      }
    };
    window.addEventListener('message', handleMessage);

    // 자식창 닫힐 때 이벤트 정리
    const cleanup = setInterval(() => {
      if (newWindow.closed) {
        clearInterval(cleanup);
        window.removeEventListener('message', handleMessage);
      }
    }, 500);
  };

  const sendHomework = async () => {
    if (classDetails?.students?.length > 0) {
      const body = {
        classId: classDetails.classId,
        stageId: lessonStage.stage.stageId,
      };
      const data = await assignHomework(body);
      if (data?.skip) return;
      homeworkTransferToast();
    } else {
      checkToast('클래스에 학생이 없어요.');
    }
  };

  const redirectToLmsLearningResult = () => {
    // 모니터의 해상도 크기 가져오기.
    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;

    window.open(
      `https://${process.env.REACT_APP_ENV === 'PRODUCTION' ? '' : 'dev-'}lms.arkmath.co.kr/learning/classes/chapter/lesson/stage?classId=${classDetails.classId}&lessonInfoId=${lessonInfo.lessonInfoId}&stageId=${lessonStage.stage.stageId}`,
      'lms_window',
      `top=0,left=0,width=${screenWidth},height=${screenHeight}`
    );
  };

  const goToLessonsPage = () => {
    const queryString = location.search;
    navigate(`/lesson/list/${queryString}`);
  };

  useEffect(() => {
    if (showPasswordUsageModal) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [showPasswordUsageModal]);

  const openPasswordUsageModel = () => {
    setShowPasswordUsageModal(true);
  };

  useEffect(() => {
    if (showLoginQrModal) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [showLoginQrModal]);

  const openLoginQrModal = () => {
    setShowLoginQrModal(true);
  };

  const handleIsPasswordOnToggle = (v) => {
    const updatedTeacher = {
      ...teacher,
      isPasswordOn: v
    };
    setTeacher(updatedTeacher);
    submitUpdateTeacher(updatedTeacher);
  };

  const submitUpdateTeacher = async (body) => {
    const data = await updateTeacher(body);
    if (data?.skip) return;
    setTeacher(data.teacher);
  };

  const closeThing = () => {
    setShowPasswordUsageModal(false);
    setShowLoginQrModal(false);
  };

  useKeyPress(27, closeThing);

  useEffect(() => {
    setLessonStage(classDetails?.lessonStages?.find((value) => value.lessonStageId === parseInt(lessonStageIdParam)));
  }, [classDetails]);

  useEffect(() => {
    window.scrollTo(0, 0); // 페이지 로드 시 스크롤을 최상단으로 이동
  }, []);

  return (
    <div className={'pb-[200px] relative'}>
      <div className={'w-[68px] h-[68px] flex justify-center items-center border-[1px] border-[#dbdbdb] bg-[#ffffff] shadow-[0_2px_7px_0_rgba(0,0,0,0.10)] rounded-full absolute top-[20px] right-[20px] cursor-pointer select-none'} onClick={startReload}>
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
          <g clipPath="url(#clip0_1781_9186)">
            <path d="M33.2216 21.7353C32.9068 24.1352 31.9448 26.4042 30.4385 28.2989C28.9323 30.1936 26.9386 31.6425 24.6715 32.4903C22.4044 33.3381 19.9492 33.5528 17.5693 33.1112C15.1895 32.6697 12.9747 31.5887 11.1625 29.9841C9.3503 28.3795 8.00909 26.3119 7.28269 24.003C6.55628 21.6941 6.4721 19.231 7.03916 16.8779C7.60621 14.5248 8.80313 12.3704 10.5015 10.6459C12.1999 8.9213 14.3357 7.69159 16.6799 7.08861C23.1782 5.42194 29.9049 8.76694 32.3882 15.0003" stroke="#50555F" strokeWidth="3.33333" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M33.3333 6.6665V14.9998H25" stroke="#50555F" strokeWidth="3.33333" strokeLinecap="round" strokeLinejoin="round"/>
          </g>
          <defs>
            <clipPath id="clip0_1781_9186">
              <rect width="40" height="40" fill="white"/>
            </clipPath>
          </defs>
        </svg>
      </div>
      <PasswordUsageModal
        showPasswordUsageModal={showPasswordUsageModal}
        setShowPasswordUsageModal={setShowPasswordUsageModal}
      />
      <LoginQrCodeModal
        showLoginQrModal={showLoginQrModal}
        setShowLoginQrModal={setShowLoginQrModal}
        classDetails={classDetails}
      />
      <div className={'h-[160px] flex justify-center items-center relative'}>
        <Logo/>
        <div className={'w-[68px] h-[68px] absolute bg-[#A3C6FF] flex justify-center items-center rounded-[15px] left-[24px] top-[24px] cursor-pointer'} onClick={goToLessonsPage}>
          <svg xmlns="http://www.w3.org/2000/svg" width="23" height="42" viewBox="0 0 23 42" fill="none">
            <path d="M19.2549 38.3091L2.7454 19.9818L19.2549 3.6909" stroke="#50555F" strokeWidth="4.14325" strokeLinecap="square" strokeLinejoin="round"/>
          </svg>
        </div>
      </div>
      <div className={'flex justify-center'}>
        <div className={'rounded-[20px] border-[0.5px] border-[#dadada] shadow-[0_2px_7px_0_rgba(0,0,0,0.10)] bg-white w-[66%]'}>
          <div>
            <div className={'py-[20px] px-[30px] text-[22px]'}>
              <div className={'flex justify-between items-center'}>
                <div className={'text-[#50555F] text-[24px] font-[pd-b]'}>
                  {lessonStage?.stage?.name}
                </div>
                <div className={'flex'}>
                  <button type={'button'} className={'flex justify-center items-center py-[8px] px-[20px] rounded-full border border-[#557CFC] text-[#355BDF] text-[16px] font-[pd-eb]'}
                          onClick={redirectToLmsLearningResult}>학습 결과보기&nbsp;&nbsp;
                    <svg xmlns="http://www.w3.org/2000/svg" width="11" height="16" viewBox="0 0 11 16" fill="none">
                      <path d="M0.560059 14.1199L6.66672 7.99987L0.560059 1.87987L2.44006 -0.0001297L10.4401 7.99987L2.44006 15.9999L0.560059 14.1199Z" fill="#355BDF"/>
                    </svg>
                  </button>
                  <button type={'button'} className={'flex justify-center items-center py-[8px] px-[20px] rounded-full border border-[#557CFC] text-[#355BDF] text-[16px] font-[pd-eb] ml-[20px]'}
                          onClick={sendHomework}>숙제 전송&nbsp;&nbsp;
                    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
                      <path d="M17.5002 0.999924L8.7002 9.79992" stroke="#355BDF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                      <path d="M17.5 0.999962L11.9 17L8.7 9.79996L1.5 6.59996L17.5 0.999962Z" stroke="#355BDF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <div className={'bg-gray-200 h-[1px]'}></div>
          </div>
          <div className={'flex justify-between items-center py-[20px] px-[30px]'}>
            <div className={'flex'}>
              <div>
                {lessonStage?.stage?.thumbnailImage ? (
                  <img
                    src={`https://sla207651-sla207651.ktcdn.co.kr/stage_thumbnails/${lessonStage.stage.thumbnailImage}.png`}
                    className={'rounded-[8px] max-w-[300px]'}
                    alt={lessonStage.stage.thumbnailImage || 'thumbnailImage'}
                  />
                ) : (
                  <div>Loading...</div>
                )}
              </div>
              <div className={'w-[200px] flex justify-center items-center'}>
                <div>
                  <div className={'flex justify-center items-center'}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="51" height="51" viewBox="0 0 51 51" fill="none">
                      <g clipPath="url(#clip0_938_10534)">
                        <path
                          d="M6.51294 25.6609C6.51294 28.1411 7.00146 30.5971 7.95062 32.8886C8.89978 35.1801 10.291 37.2621 12.0448 39.016C13.7986 40.7698 15.8807 42.161 18.1722 43.1101C20.4636 44.0593 22.9196 44.5478 25.3999 44.5478C27.8802 44.5478 30.3362 44.0593 32.6276 43.1101C34.9191 42.161 37.0012 40.7698 38.755 39.016C40.5088 37.2621 41.9 35.1801 42.8492 32.8886C43.7983 30.5971 44.2869 28.1411 44.2869 25.6609C44.2869 23.1806 43.7983 20.7246 42.8492 18.4331C41.9 16.1417 40.5088 14.0596 38.755 12.3058C37.0012 10.552 34.9191 9.16075 32.6276 8.21159C30.3362 7.26244 27.8802 6.77391 25.3999 6.77391C22.9196 6.77391 20.4636 7.26244 18.1722 8.21159C15.8807 9.16075 13.7986 10.552 12.0448 12.3058C10.291 14.0596 8.89978 16.1417 7.95062 18.4331C7.00146 20.7246 6.51294 23.1806 6.51294 25.6609Z"
                          stroke="#2CB000" strokeWidth="4.1971" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M19.1042 25.6609L23.3013 29.858L31.6956 21.4638" stroke="#2CB000" strokeWidth="4.1971" strokeLinecap="round" strokeLinejoin="round"/>
                      </g>
                      <defs>
                        <clipPath id="clip0_938_10534">
                          <rect width="50.3652" height="50.3652" fill="white" transform="translate(0.217285 0.478256)"/>
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <div className={'flex justify-center items-center text-[25px] text-[#50555F] font-[pd-b] mt-[10px]'}>
                    학습완료
                  </div>
                  <div className={'flex justify-center items-center text-[25px] mt-[10px] text-[#557CFC] font-[pd-b]'}>
                    {lessonStage?.completedLearningCount ?? '0'}명
                  </div>
                </div>
              </div>
              <div className={'border'}></div>
              <div className={'w-[200px] flex justify-center items-center'}>
                <div>
                  <div className={'flex justify-center items-center'}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="51" height="51" viewBox="0 0 51 51" fill="none">
                      <g clipPath="url(#clip0_938_10539)">
                        <path d="M17.9808 8.22179C15.6894 9.1703 13.6071 10.5608 11.853 12.314" stroke="#D3D3D3" strokeWidth="4.1971" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M7.76099 18.442C6.80942 20.73 6.3174 23.183 6.31299 25.661" stroke="#D3D3D3" strokeWidth="4.1971" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M7.76074 32.8798C8.70926 35.1712 10.0998 37.2534 11.8529 39.0075" stroke="#D3D3D3" strokeWidth="4.1971" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M17.981 43.0999C20.269 44.0515 22.7219 44.5435 25.2 44.5479" stroke="#D3D3D3" strokeWidth="4.1971" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M32.4189 43.0995C34.7104 42.151 36.7926 40.7605 38.5467 39.0074" stroke="#D3D3D3" strokeWidth="4.1971" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M42.6389 32.8799C43.5905 30.5918 44.0825 28.1389 44.0869 25.6609" stroke="#D3D3D3" strokeWidth="4.1971" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M42.639 18.4421C41.6905 16.1507 40.3 14.0685 38.5469 12.3144" stroke="#D3D3D3" strokeWidth="4.1971" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M32.419 8.22191C30.1309 7.27034 27.678 6.77832 25.2 6.77391" stroke="#D3D3D3" strokeWidth="4.1971" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M18.9043 25.6609L23.1014 29.858L31.4956 21.4638" stroke="#D3D3D3" strokeWidth="4.1971" strokeLinecap="round" strokeLinejoin="round"/>
                      </g>
                      <defs>
                        <clipPath id="clip0_938_10539">
                          <rect width="50.3652" height="50.3652" fill="white" transform="translate(0.017334 0.478256)"/>
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <div className={'flex justify-center items-center text-[25px] text-[#50555F] font-[pd-b] mt-[10px]'}>
                    미진행
                  </div>
                  <div className={'flex justify-center items-center text-[25px] mt-[10px] text-[#557CFC] font-[pd-b]'}>
                    {classDetails?.studentCount - lessonStage?.completedLearningCount}명
                  </div>
                </div>
              </div>
            </div>
            <div className={'flex justify-center items-center'}>
              <button type={'button'} className={'flex justify-center items-center bg-[#557cfc] text-[#ffffff] w-[150px] h-[150px] rounded-full text-[22px] ml-[20px]'} onClick={startLearning}>
                <div>
                  <div className={'flex justify-center items-center'}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="33" height="38" viewBox="0 0 33 38" fill="none">
                      <path
                        d="M31.3548 16.3181C33.4194 17.5101 33.4194 20.4899 31.3548 21.6819L4.64516 37.1027C2.58065 38.2947 1.98735e-06 36.8047 2.09156e-06 34.4208L3.43969e-06 3.57916C3.54389e-06 1.19526 2.58065 -0.294674 4.64516 0.897275L31.3548 16.3181Z"
                        fill="white"/>
                    </svg>
                  </div>
                  <div className={'font-[pd-eb] text-[22px] mt-[10px] text-[#FFFB9F]'}>
                    학습 시작
                  </div>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className={'flex justify-center mt-[30px]'}>
        <div className={'rounded-[20px] border-[0.5px] border-[#dadada] shadow-[0_2px_7px_0_rgba(0,0,0,0.10)] bg-white py-[30px] px-[50px] w-[66%]'}>
          <div className={'flex justify-center'}>
            <Class lessonInfo={lessonInfo} classes={classes} setClasses={setClasses} classDetails={classDetails} setClassDetails={setClassDetails} width={'60%'} listHeight={300}/>
          </div>
          <div className={'flex justify-center font-[pd-m] text-[#50555F] mt-[20px] text-[20px]'}>
            {teacher?.name} | {school?.name}
          </div>
          <div className={'flex justify-between items-center mt-[50px]'}>
            <div className={'flex justify-center items-center'}>
              <div className={'flex justify-center items-center'}>
                <Switch className={'cursor-pointer ml-[10px]'}
                        onChange={handleIsPasswordOnToggle}
                        checked={(teacher && teacher.isPasswordOn) || false}
                        checkedIcon={false}
                        uncheckedIcon={false}
                        handleDiameter={40} // 스위치 핸들의 직경 크기
                        offColor={'#D8D8D8'}
                        onColor={'#FFDC72'}
                        width={70}
                        height={34}
                        boxShadow={'0 0 2px 2px rgba(0, 0, 0, 0.1)'}/>
                <div className={'text-[20px] font-[pd-b] text-[#5e6169] ml-[10px]'}>
                  접속번호 인증하기
                </div>
                <div className={'flex justify-center items-center cursor-pointer ml-[6px]'} onClick={openPasswordUsageModel}>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#5e6169" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round"
                          d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 5.25h.008v.008H12v-.008Z"/>
                  </svg>
                </div>
              </div>
            </div>
            <div className={'text-[22px] flex justify-center'}>
              <div className={'flex'}>
                <button type={'button'} className={'flex justify-center items-center py-[8px] px-[20px] rounded-full border border-[#557CFC] text-[#355BDF] text-[20px] font-[pd-eb]'}
                        onClick={openLoginQrModal}>로그인 QR 인쇄
                </button>
              </div>
              <div className={'flex'}>
                <button type={'button'} className={'flex justify-center items-center py-[8px] px-[20px] rounded-full border border-[#557CFC] text-[#355BDF] text-[20px] font-[pd-eb] ml-[20px]'}
                        onClick={redirectToLmsClassManagement}>클래스 관리
                </button>
              </div>
            </div>
          </div>
          <div className={'mt-[30px]'}>
            <table className={'w-full border-separate border-spacing-0'}>
              <thead>
              <tr className={'bg-[#FBFBFB] h-[70px]'}>
                <th className={'w-[10%] text-[25px] text-[#5E6169] border-t-[2px] border-t-[#292929] border-b text-center'}>번호</th>
                <th className={'w-[70%] text-[25px] text-[#5E6169] border-t-[2px] border-t-[#292929] border-b text-center'}>이름</th>
                <th className={'w-[20%] text-[25px] text-[#5E6169] border-t-[2px] border-t-[#292929] border-b text-center'}>접속번호</th>
              </tr>
              </thead>
              <tbody>
              {classDetails?.students?.map((value, index) => (
                <tr key={index} className={'h-[70px] text-[20px] text-[#5E6169] font-[pd-m]'}>
                  <td className={`${index === classDetails?.students?.length - 1 ? 'border-b-[2px] border-b-[#292929]' : 'border-b'} text-center`}>{value.number}</td>
                  <td className={`${index === classDetails?.students?.length - 1 ? 'border-b-[2px] border-b-[#292929]' : 'border-b'} text-center`}>{value.name}</td>
                  <td className={`${index === classDetails?.students?.length - 1 ? 'border-b-[2px] border-b-[#292929]' : 'border-b'} text-center`}>{value.password}</td>
                </tr>
              ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Lesson;
