import { useEffect, useRef, useState } from 'react';
import './Class.css';
import { getClassDetails, updateClass } from '../../network/Endpoint';

const Class = ({ lessonInfo, classes, setClasses, classDetails, setClassDetails, width = '50%', listHeight = 400 }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const changeClass = async (value) => {
    setIsOpen(false);
    let body = {
      classId: value.classId,
      isMain: true,
    };
    await updateClass(body);
    body = {
      classId: value.classId,
      lessonInfoId: lessonInfo.lessonInfoId,
    };
    const data = await getClassDetails(body);
    if (data?.skip) return;
    let currentClassDetails = data.classDetails;
    setClassDetails(currentClassDetails);
    const updatedClasses = classes.map(v =>
      v.classId === currentClassDetails.classId
        ? { ...v, studentCount: currentClassDetails.studentCount }
        : v
    );
    setClasses(updatedClasses);
  };

  const toggleList = () => {
    setIsOpen((prev) => !prev);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div
      className={'relative z-[100] select-none'}
      style={{ width: `${width}` }}
      ref={dropdownRef}
    >
      <div
        className={`toggle-button ${isOpen ? 'open' : ''}`}
        onClick={toggleList}
      >
        {classDetails?.name} ({classDetails?.studentCount}명)
        <div className={'absolute right-[40px]'}>
          <svg xmlns="http://www.w3.org/2000/svg" width="23" height="13" viewBox="0 0 23 13" fill="none">
            <path d="M21.1001 2.42188L10.9354 11.5784L1.9001 2.42188" stroke="#9298A6" strokeWidth="2.29794" strokeLinecap="square" strokeLinejoin="round"/>
          </svg>
        </div>
      </div>
      <div className={`dropdown ${isOpen ? 'open' : ''}`} style={{ maxHeight: isOpen ? `${listHeight}px` : `0` }}>
        <div className={'px-[10px]'}>
          {isOpen && classDetails && classes?.length > 0 ? <div className={'bg-[#557CFC] h-[0.5px]'}></div> : null}
        </div>
        <div className="dropdown-list" style={{ maxHeight: `${listHeight}px` }}>
          {classDetails && classes?.length > 0 && (
            classes
              // .filter((value) => value.classId !== classDetails.classId)
              .map((value, index) => (
                <div
                  key={index}
                  className={`dropdown-item`}
                  onClick={() => changeClass(value)}
                >
                  <div
                    className={`w-full h-full flex justify-center items-center rounded-[12px] ${value.classId === classDetails.classId ? 'bg-[#eefaff] text-[#3586df] hover:bg-[#EEF6FF] hover:text-[#355BDF]' : 'hover:bg-[#EEF6FF] hover:text-[#355BDF]'}`}>
                    <div className={'w-full text-center text-[12px]'}>{value.createdDate}</div>
                    <div className={'w-full text-center text-[20px]'}>{value.name}</div>
                    <div className={'w-full text-center text-[12px]'}>{value.studentCount}명</div>
                  </div>
                </div>
              )))}
        </div>
      </div>
    </div>
  );
};

export default Class;
