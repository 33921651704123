import toast from 'react-hot-toast';
import Icon from '../../resources/images/icon.png';

const successToast = (message) => {
  toast.success(message);
};

const checkToast = (message) => {
  toast(message, {
    icon: '⚠️'
  });
};

const appTransferToast = () => {
  toast.custom(
    (t) => (
      <div
        style={{
          opacity: t.visible ? 1 : 0,
          transition: 'opacity 3000ms ease-in-out',
          width: '552px',
          height: '140px',
          background: '#1f1f1f',
          color: '#fff',
          borderRadius: '30px',
          fontSize: '25px',
          boxShadow: '0px 2px 7px 0px rgba(0, 0, 0, 0.15)',
        }}
        className={'flex justify-center items-center'}
      >
        <img src={Icon} alt="icon"/>
        <div className={'ml-[20px]'}>
          <div className={'text-[25px] text-[#fff] font-[pd-b] flex items-center'}>
            스테이지 전송이 완료되었습니다.
          </div>
          <div className={'text-[20px] text-[#fff] font-[pd-b] flex items-center mt-[6px] opacity-40'}>
            학생들을 지도하여 아크수학에서 학습하세요!
          </div>
        </div>
      </div>
    ),
    {
      duration: 3000
    }
  );
};

const homeworkTransferToast = () => {
  toast.custom(
    (t) => (
      <div
        style={{
          opacity: t.visible ? 1 : 0,
          transition: 'opacity 3000ms ease-in-out',
          width: '552px',
          height: '140px',
          background: '#1f1f1f',
          color: '#fff',
          borderRadius: '30px',
          fontSize: '25px',
          boxShadow: '0px 2px 7px 0px rgba(0, 0, 0, 0.15)',
        }}
        className={'flex justify-center items-center'}
      >
        <img src={Icon} alt="icon"/>
        <div className={'ml-[20px]'}>
          <div className={'text-[25px] text-[#fff] font-[pd-b] flex items-center'}>
            숙제가 전송되었습니다.
          </div>
          <div className={'text-[20px] text-[#fff] font-[pd-b] flex items-center mt-[6px] opacity-40'}>
            학습 관리 기능(LMS)에서 결과를 확인해요!
          </div>
        </div>
      </div>
    ),
    {
      duration: 3000
    }
  );
};

export { successToast, checkToast, appTransferToast, homeworkTransferToast };
