import TopBar from './TopBar';
import Content from './Content';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getTeacherDetails, updateTeacher } from '../../network/Endpoint';

const LessonPage = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [teacher, setTeacher] = useState({});
  const [isPaidVersion, setIsPaidVersion] = useState(false);
  const [school, setSchool] = useState({});
  const [lessonInfo, setLessonInfo] = useState(null);
  const [classes, setClasses] = useState([]);
  const [classDetails, setClassDetails] = useState([]);
  const [showTermsOfServiceModal, setShowTermsOfServiceModal] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const encodedImTeacherLoginIdParam = searchParams.get('mId') || '';
  const imLessonIdParam = searchParams.get('lessonId') || '';

  const fetchTeacherDetails = async (body) => {
    const data = await getTeacherDetails(body);
    if (data?.skip) return;
    return data;
  };

  const agreeTermsOfService = async () => {
    const body = {
      teacherId: teacher?.teacherId,
      isTermsOfServiceAgreed: true,
    };
    const data = await updateTeacher(body);
    if (data?.skip) return;
    setShowTermsOfServiceModal(false);
  };

  const startReload = () => {
    window.location.reload();
  }

  useEffect(() => {
    if (encodedImTeacherLoginIdParam && imLessonIdParam) {
      let body = {
        encodedImTeacherLoginId: encodedImTeacherLoginIdParam,
        imLessonId: imLessonIdParam,
      };
      fetchTeacherDetails(body)
        .then((data) => {
          setIsLoading(true);
          if (data !== undefined) {
            // console.log(data);
            setTeacher(data.teacher);
            setIsPaidVersion(data.isPaidVersion);
            setSchool(data.school);
            setLessonInfo(data.lessonInfo);
            setClasses(data.classes);
            setClassDetails(data.classDetails);
            if (data.teacher.isTermsOfServiceAgreed) {
              setShowTermsOfServiceModal(false);
            }
            setIsLoading(false);
          }
        });
    } else {
      // If encodedImTeacherLoginIdParam and imLessonIdParam do not exist, redirect to the empty page.
      navigate(`/`);
    }
  }, [encodedImTeacherLoginIdParam, imLessonIdParam]);

  return (
    <div className={'min-w-[1920px]'}>
      {!isLoading && showTermsOfServiceModal ? (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-[10000]">
          <div className={'w-[936px] h-[560px] px-[80px] bg-white rounded-[20px]'}>
            <div className={'mt-[30px]'}>
              <div className={'text-[#50555F] text-[36px] font-[pd-eb] flex justify-center items-center'}>
                이용 약관 동의
              </div>
              <div className={'text-[#50555F] text-[30px] font-[pd-b] flex justify-center items-center'}>
                내용
              </div>
            </div>
            <div className={'mt-[30px]'}>
              <div className={'border cursor-pointer select-none'} onClick={() => agreeTermsOfService()}>동의함</div>
            </div>
          </div>
        </div>
      ) : null}
      {/*<TopBar/>*/}
      <Content
        startReload={startReload}
        teacher={teacher}
        setTeacher={setTeacher}
        isPaidVersion={isPaidVersion}
        school={school}
        lessonInfo={lessonInfo}
        classes={classes}
        setClasses={setClasses}
        classDetails={classDetails}
        setClassDetails={setClassDetails}
      />
    </div>
  );
};

export default LessonPage;
