import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { QRCodeCanvas } from 'qrcode.react';
import LearningBgItem1 from '../../resources/images/learning_bg_item_1.png';
import LearningBgItem2 from '../../resources/images/learning_bg_item_2.png';
import LearningBgItem3 from '../../resources/images/learning_bg_item_3.png';
import { startLessonLearningApp } from '../../network/Endpoint';
import { appTransferToast, checkToast, successToast } from '../../shared/components/Toast';

const Learning = ({ startReload, teacher, lessonInfo, classDetails }) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [lessonStage, setLessonStage] = useState({});
  const [webQrCodeValue, setWebQrCodeValue] = useState('');
  const [appQrCodeValue, setAppQrCodeValue] = useState('');
  const [showLearningStatus, setShowLearningStatus] = useState(false);
  const [socket, setSocket] = useState(null);
  const [learningStatuses, setLearningStatuses] = useState([]);
  const [isLearningStatusesSet, setIsLearningStatusesSet] = useState(false);
  const [selectedTab, setSelectedTab] = useState('web');
  const [isWebQrZoomed, setIsWebQrZoomed] = useState(false);
  const [isAppQrZoomed, setIsAppQrZoomed] = useState(false);

  const lessonStageIdParam = searchParams.get('lessonStageId') || '';

  const changeLearningTab = () => {
    setShowLearningStatus(!showLearningStatus);
  };

  const goToLessonPage = () => {
    const queryString = location.search;
    navigate(`/lesson/details/${queryString}`);
  };

  const redirectToLmsLearningResult = () => {
    // 모니터의 해상도 크기 가져오기.
    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;

    window.open(
      `https://${process.env.REACT_APP_ENV === 'PRODUCTION' ? '' : 'dev-'}lms.arkmath.co.kr/learning/classes/chapter/lesson/stage?classId=${classDetails.classId}&lessonInfoId=${lessonInfo.lessonInfoId}&stageId=${lessonStage.stage.stageId}`,
      'lms_window',
      `top=0,left=0,width=${screenWidth},height=${screenHeight}`
    );
  };

  const redirectToLmsLoginQr = () => {
    // 모니터의 해상도 크기 가져오기.
    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;

    window.open(
      `https://${process.env.REACT_APP_ENV === 'PRODUCTION' ? '' : 'dev-'}lms.arkmath.co.kr/management?page=qrCode&teacherId=${teacher.teacherId}`,
      'lms_window',
      `top=0,left=0,width=${screenWidth},height=${screenHeight}`
    );
  };

  const changeTab = (value) => {
    setSelectedTab(value);
  };

  const sendClass = async () => {
    const body = {
      classId: classDetails?.classId,
      lessonInfoId: lessonInfo?.lessonInfoId,
      stageId: lessonStage?.stage?.stageId,
    };
    const data = await startLessonLearningApp(body);
    if (data?.skip) return;
    appTransferToast();
  };

  useEffect(() => {
    setLessonStage(classDetails?.lessonStages?.find((value) => value.lessonStageId === parseInt(lessonStageIdParam)));
    if (classDetails?.students && !isLearningStatusesSet) {
      const learningStatuses = classDetails.students.map(item => ({
        studentId: item.studentId,
        name: item.name,
        number: item.number,
        learningStatus: 'not_started'
      }));
      setLearningStatuses(learningStatuses);
      setIsLearningStatusesSet(true);
    }
  }, [classDetails]);

  useEffect(() => {
    setWebQrCodeValue(`https://1promath-dev.shop/onepromath_webgl/arkmath/ArkMathUnity/index.html?classId=${classDetails?.classId}&lessonInfoId=${lessonInfo?.lessonInfoId}&stageId=${lessonStage?.stage?.stageId}&scheck=${process.env.REACT_APP_ENV === 'PRODUCTION' ? 'prod' : 'dev'}`);
    setAppQrCodeValue(`${process.env.REACT_APP_WEB_URL}/qrcode?type=lesson&classId=${classDetails?.classId}&lessonInfoId=${lessonInfo?.lessonInfoId}&stageId=${lessonStage?.stage?.stageId}`);
  }, [classDetails, lessonInfo, lessonStage]);

  // WebSocket 연결
  useEffect(() => {
    if (classDetails?.classId && lessonInfo?.lessonInfoId && lessonStage?.stage?.stageId && isLearningStatusesSet) {
      const uniqueId = `${classDetails.classId}_${lessonInfo.lessonInfoId}_${lessonStage.stage.stageId}`;
      const ws = new WebSocket(`${process.env.REACT_APP_WS_HOST}/ws?id=${uniqueId}`);
      ws.onopen = () => {
        // Start sending ping messages every 10 seconds
        const pingInterval = setInterval(() => {
          if (ws.readyState === WebSocket.OPEN) {
            console.log('[WebSocket] ping');
            ws.send('ping');
          } else {
            console.log('[WebSocket] ping error');
          }
        }, 10000);

        // Cleanup interval on unmount
        return () => clearInterval(pingInterval);
      };
      ws.onmessage = (event) => {
        try {
          const message = JSON.parse(event.data);
          if (message.type === 'connection' && message.status === 'established') {
            console.log('[WebSocket] Connection established, ID:', message.id);
          }
          if (message.type === 'notification') {
            let content = JSON.parse(message.content);
            const updateMap = new Map(content.map(update => [update.studentId, update]));
            const updatedStatuses = learningStatuses.map(value => {
              if (updateMap.has(value.studentId)) {
                return { ...value, ...updateMap.get(value.studentId) }; // 기존 값과 업데이트된 값 병합
              }
              return value;
            });
            setLearningStatuses(updatedStatuses);
          }
          if (message.type === 'pingPong') {
            console.log(`[WebSocket] ${message.content}`);
          }
        } catch (error) {
          console.error('[WebSocket] Message parsing error:', error);
        }
      };
      ws.onerror = (error) => {
        console.error('[WebSocket] Error:', error);
      };
      ws.onclose = () => {
        console.log('[WebSocket] Connection closed');
      };
      setSocket(ws);

      // 컴포넌트 언마운트 시 WebSocket 종료
      return () => {
        if (ws.readyState === WebSocket.OPEN) {
          ws.close();
        }
      };
    }
  }, [classDetails, lessonInfo, lessonStage]);

  const completed = learningStatuses?.filter((value) => value.learningStatus === 'completed') || [];
  const inProgress =
    learningStatuses?.filter(
      (value) => value.learningStatus !== 'completed' && value.learningStatus !== 'not_started'
    ) || [];
  const notStarted = learningStatuses?.filter((value) => value.learningStatus === 'not_started') || [];

  const handleWebQrClick = () => {
    setIsWebQrZoomed(true);
  };

  const handleWebQrBackdropClick = () => {
    setIsWebQrZoomed(false);
  };

  const handleAppQrClick = () => {
    setIsAppQrZoomed(true);
  };

  const handleAppQrBackdropClick = () => {
    setIsAppQrZoomed(false);
  };

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        successToast('복사 완료!');
      })
      .catch((error) => {
        console.error('copy error:', error);
      });
  };

  const startNextLessonLearning = () => {
    if (classDetails?.lessonStages && lessonStage?.lessonStageId) {
      const targetIndex = classDetails.lessonStages.findIndex(item => item.lessonStageId === lessonStage.lessonStageId);
      const hasNextIndex = targetIndex !== -1 && targetIndex < classDetails.lessonStages.length - 1;
      if (hasNextIndex) {
        setIsLearningStatusesSet(false);
        const searchParams = new URLSearchParams(location.search);
        searchParams.set('lessonStageId', classDetails.lessonStages[(targetIndex + 1)].lessonStageId);
        navigate(`${location.pathname}?${searchParams.toString()}`, { replace: true });
        setLessonStage(classDetails.lessonStages[(targetIndex + 1)]);
        const learningStatuses = classDetails.students.map(item => ({
          studentId: item.studentId,
          name: item.name,
          number: item.number,
          learningStatus: 'not_started'
        }));
        setLearningStatuses(learningStatuses);
        setIsLearningStatusesSet(true);
        successToast("다음 수업 시작 완료!")
      } else {
        checkToast('다음 수업이 없어요!');
      }
    } else {
      checkToast('다시 시도해 주세요!');
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0); // 페이지 로드 시 스크롤을 최상단으로 이동
  }, []);

  return (
    <div className={'bg-[#eaf1ff] min-h-[1080px] h-[100vh] relative'}>
      <div className={'w-[68px] h-[68px] flex justify-center items-center border-[1px] border-[#dbdbdb] bg-[#ffffff] shadow-[0_2px_7px_0_rgba(0,0,0,0.10)] rounded-full absolute top-[20px] right-[20px] cursor-pointer select-none z-[9999]'} onClick={startReload}>
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
          <g clipPath="url(#clip0_1781_9186)">
            <path d="M33.2216 21.7353C32.9068 24.1352 31.9448 26.4042 30.4385 28.2989C28.9323 30.1936 26.9386 31.6425 24.6715 32.4903C22.4044 33.3381 19.9492 33.5528 17.5693 33.1112C15.1895 32.6697 12.9747 31.5887 11.1625 29.9841C9.3503 28.3795 8.00909 26.3119 7.28269 24.003C6.55628 21.6941 6.4721 19.231 7.03916 16.8779C7.60621 14.5248 8.80313 12.3704 10.5015 10.6459C12.1999 8.9213 14.3357 7.69159 16.6799 7.08861C23.1782 5.42194 29.9049 8.76694 32.3882 15.0003" stroke="#50555F" strokeWidth="3.33333" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M33.3333 6.6665V14.9998H25" stroke="#50555F" strokeWidth="3.33333" strokeLinecap="round" strokeLinejoin="round"/>
          </g>
          <defs>
            <clipPath id="clip0_1781_9186">
              <rect width="40" height="40" fill="white"/>
            </clipPath>
          </defs>
        </svg>
      </div>
      {isWebQrZoomed && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
          onClick={handleWebQrBackdropClick}
        >
          <div
            className="bg-white p-4 rounded shadow-lg"
            onClick={(e) => e.stopPropagation()} // Prevent closing when QR is clicked
          >
            <QRCodeCanvas value={webQrCodeValue} size={500} bgColor="#ffffff" fgColor="#000000"/>
          </div>
        </div>
      )}
      {isAppQrZoomed && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
          onClick={handleAppQrBackdropClick}
        >
          <div
            className="bg-white p-4 rounded shadow-lg"
            onClick={(e) => e.stopPropagation()} // Prevent closing when QR is clicked
          >
            <QRCodeCanvas value={appQrCodeValue} size={500} bgColor="#ffffff" fgColor="#000000"/>
          </div>
        </div>
      )}
      <img src={LearningBgItem1} className={'absolute right-[30px]'} alt={'LearningBgItem1'}/>
      <img src={LearningBgItem2} className={'absolute left-0 bottom-0'} alt={'LearningBgItem2'}/>
      <img src={LearningBgItem3} className={'absolute left-0 top-0'} alt={'LearningBgItem3'}/>
      <div className={'h-[100px] relative'}>
        <div className={'w-full h-[100px] bg-gradient-to-b from-[rgba(0,0,0,0.70)] to-[rgba(0,0,0,0.35)] absolute left-0 top-0'}>
        </div>
        <div className={'w-full flex justify-between items-center h-[100px] absolute left-0 top-0'}>
          <div className={'flex justify-center items-center'}>
            <div className={'w-[68px] h-[68px] bg-[#A3C6FF] flex justify-center items-center rounded-[15px] cursor-pointer ml-[24px]'} onClick={goToLessonPage}>
              <svg xmlns="http://www.w3.org/2000/svg" width="23" height="42" viewBox="0 0 23 42" fill="none">
                <path d="M19.2549 38.3091L2.7454 19.9818L19.2549 3.6909" stroke="#50555F" strokeWidth="4.14325" strokeLinecap="square" strokeLinejoin="round"/>
              </svg>
            </div>
            <div className={'ml-[24px]'}>
              <span className={'text-[#FFFFA2] text-[30px] font-[pd-b]'}>[AI아크수학]</span><span className={'text-[#ffffff] text-[30px] font-[pd-b]'}>&nbsp;&nbsp;{lessonStage?.stage?.name}</span>
            </div>
          </div>
          <div className={'flex justify-center items-center mr-[130px]'}>
            <div
              className={`flex justify-center items-center w-[204px] h-[49px] ${showLearningStatus ? 'bg-[#A3C6FF] border-[#A3C6FF]' : 'bg-[#ffffff] border-[#DBDBDB]'} rounded-[15px] border-[1px] shadow-[0_2px_7px_0_rgba(0,0,0,0.15)] select-none cursor-pointer`}
              onClick={changeLearningTab}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                <g clipPath="url(#clip0_1621_55126)">
                  <path
                    d="M8 7.5C8 8.56087 8.42143 9.57828 9.17157 10.3284C9.92172 11.0786 10.9391 11.5 12 11.5C13.0609 11.5 14.0783 11.0786 14.8284 10.3284C15.5786 9.57828 16 8.56087 16 7.5C16 6.43913 15.5786 5.42172 14.8284 4.67157C14.0783 3.92143 13.0609 3.5 12 3.5C10.9391 3.5 9.92172 3.92143 9.17157 4.67157C8.42143 5.42172 8 6.43913 8 7.5Z"
                    stroke={showLearningStatus ? '#355BDF' : '#50555F'} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M6 21.5V19.5C6 18.4391 6.42143 17.4217 7.17157 16.6716C7.92172 15.9214 8.93913 15.5 10 15.5H14" stroke={showLearningStatus ? '#355BDF' : '#50555F'} strokeWidth="3"
                        strokeLinecap="round"
                        strokeLinejoin="round"/>
                  <path d="M15 19.5L17 21.5L21 17.5" stroke={showLearningStatus ? '#355BDF' : '#50555F'} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                </g>
                <defs>
                  <clipPath id="clip0_1621_55126">
                    <rect width="24" height="24" fill="white" transform="translate(0 0.5)"/>
                  </clipPath>
                </defs>
              </svg>
              &nbsp;
              <div className={`text-[18px] ${showLearningStatus ? 'text-[#355BDF]' : 'text-[#50555f]'}  font-[pd-eb]`}>접속/학습 현황 보기</div>
            </div>
            <div
              className={`flex justify-center items-center w-[173px] h-[49px] bg-[#ffffff] border-[#DBDBDB] rounded-[15px] border-[1px] shadow-[0_2px_7px_0_rgba(0,0,0,0.15)] select-none cursor-pointer ml-[16px]`}
              onClick={redirectToLmsLearningResult}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                <g clipPath="url(#clip0_1621_55244)">
                  <path
                    d="M15 21.5H6C5.20435 21.5 4.44129 21.1839 3.87868 20.6213C3.31607 20.0587 3 19.2957 3 18.5V17.5H13V19.5C13 20.0304 13.2107 20.5391 13.5858 20.9142C13.9609 21.2893 14.4696 21.5 15 21.5ZM15 21.5C15.5304 21.5 16.0391 21.2893 16.4142 20.9142C16.7893 20.5391 17 20.0304 17 19.5V5.5C17 5.10444 17.1173 4.71776 17.3371 4.38886C17.5568 4.05996 17.8692 3.80362 18.2346 3.65224C18.6001 3.50087 19.0022 3.46126 19.3902 3.53843C19.7781 3.6156 20.1345 3.80608 20.4142 4.08579C20.6939 4.36549 20.8844 4.72186 20.9616 5.10982C21.0387 5.49778 20.9991 5.89992 20.8478 6.26537C20.6964 6.63082 20.44 6.94318 20.1111 7.16294C19.7822 7.3827 19.3956 7.5 19 7.5H17M19 3.5H8C7.20435 3.5 6.44129 3.81607 5.87868 4.37868C5.31607 4.94129 5 5.70435 5 6.5V17.5"
                    stroke="#50555F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M9 7.5H13" stroke="#50555F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M9 11.5H13" stroke="#50555F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </g>
                <defs>
                  <clipPath id="clip0_1621_55244">
                    <rect width="24" height="24" fill="white" transform="translate(0 0.5)"/>
                  </clipPath>
                </defs>
              </svg>
              &nbsp;
              <div className={`text-[18px] text-[#50555f] font-[pd-eb]`}>학습 결과 보기</div>
            </div>
          </div>
        </div>
      </div>
      <div className={'relative mt-[120px] flex justify-center items-center'}>
        <div className={'flex justify-center'}>
          <div>
            <div className={'w-[1020px] h-[574px]'}>
              {lessonInfo?.lessonInfoId && lessonStage?.stage?.stageId ? (
                <iframe
                  src={`https://1promath-dev.shop/onepromath_webgl/arkmath/ArkMathUnity/index.html?classId=0&lessonInfoId=${lessonInfo?.lessonInfoId}&stageId=${lessonStage?.stage?.stageId}&scheck=${process.env.REACT_APP_ENV === 'PRODUCTION' ? 'prod' : 'dev'}&classLearningId=0`}
                  className={'w-[1020px] h-[574px]'}
                />
              ) : (
                <div>Loading...</div> // 데이터를 로드 중일 때 보여줄 대체 UI
              )}
            </div>
            <div className={'mt-[24px] flex justify-center'}>
              <div className={'flex justify-center items-center w-[172px] h-[59px] bg-[#a3c6ff] shadow-[0_2px_7px_0_rgba(0,0,0,0.15)] rounded-[15px] cursor-pointer select-none'}
                   onClick={startNextLessonLearning}>
                <div className={'text-[#50555f] text-[28px] font-[pd-eb]'}>다음 수업</div>
                &nbsp;
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                  <path d="M12 2.5C6.47715 2.5 2 6.97715 2 12.5C2 18.0228 6.47715 22.5 12 22.5C17.5228 22.5 22 18.0228 22 12.5C22 6.97715 17.5228 2.5 12 2.5Z" stroke="#50555F" strokeWidth="3"
                        strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M12 16.5L16 12.5L12 8.5" stroke="#50555F" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M8 12.5L16 12.5" stroke="#50555F" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
              </div>
            </div>
          </div>
          <div className={'ml-[40px]'}>
            <div className={`${showLearningStatus ? 'hidden' : ''}  w-[392px] h-[574px] relative`}>
              <div className={'flex justify-center z-10 relative'}>
                <div className={`absolute top-0 left-[14px] select-none cursor-pointer ${selectedTab === 'web' ? 'z-20' : 'z-10'}`} onClick={() => changeTab('web')}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="192" height="58" viewBox="0 0 192 58" fill="none">
                    <mask id="path-1-outside-1_1684_750" maskUnits="userSpaceOnUse" x="0" y="0" width="192" height="58" fill="black">
                      <rect fill="white" width="192" height="58"/>
                      <path fillRule="evenodd" clipRule="evenodd"
                            d="M135.493 56H56.5074H22H2C7.52285 56 12 51.5228 12 46V12C12 6.47715 16.4772 2 22 2H43.5999H148.4H170C175.523 2 180 6.47715 180 12V46C180 51.5228 184.477 56 190 56H170H135.493Z"/>
                    </mask>
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M135.493 56H56.5074H22H2C7.52285 56 12 51.5228 12 46V12C12 6.47715 16.4772 2 22 2H43.5999H148.4H170C175.523 2 180 6.47715 180 12V46C180 51.5228 184.477 56 190 56H170H135.493Z"
                          fill={selectedTab === 'web' ? 'white' : '#EEE'}/>
                    <path
                      d="M2 54C0.895431 54 0 54.8954 0 56C0 57.1046 0.895431 58 2 58V54ZM190 58C191.105 58 192 57.1046 192 56C192 54.8954 191.105 54 190 54V58ZM56.5074 58H135.493V54H56.5074V58ZM22 58H56.5074V54H22V58ZM2 58H22V54H2V58ZM10 46C10 50.4183 6.41828 54 2 54V58C8.62742 58 14 52.6274 14 46H10ZM10 12V46H14V12H10ZM22 0C15.3726 0 10 5.37258 10 12H14C14 7.58172 17.5817 4 22 4V0ZM43.5999 0H22V4H43.5999V0ZM148.4 0H43.5999V4H148.4V0ZM148.4 4H170V0H148.4V4ZM182 12C182 5.37258 176.627 0 170 0V4C174.418 4 178 7.58172 178 12H182ZM178 12V46H182V12H178ZM178 46C178 52.6274 183.373 58 190 58V54C185.582 54 182 50.4183 182 46H178ZM190 54H170V58H190V54ZM170 54H135.493V58H170V54Z"
                      fill="#DBDBDB" mask="url(#path-1-outside-1_1684_750)"/>
                    <rect y="56" width="192" height="2" fill="white"/>
                  </svg>
                  <div className={'absolute left-[50%] -translate-x-1/2 top-[50%] -translate-y-1/2 text-[#50555F] text-[20px] font-[pd-eb]'}>
                    바로 시작
                  </div>
                </div>
                <div className={`absolute top-0 right-[14px] select-none cursor-pointer ${selectedTab === 'app' ? 'z-20' : 'z-10'}`} onClick={() => changeTab('app')}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="192" height="58" viewBox="0 0 192 58" fill="none">
                    <mask id="path-1-outside-1_1684_750" maskUnits="userSpaceOnUse" x="0" y="0" width="192" height="58" fill="black">
                      <rect fill="white" width="192" height="58"/>
                      <path fillRule="evenodd" clipRule="evenodd"
                            d="M135.493 56H56.5074H22H2C7.52285 56 12 51.5228 12 46V12C12 6.47715 16.4772 2 22 2H43.5999H148.4H170C175.523 2 180 6.47715 180 12V46C180 51.5228 184.477 56 190 56H170H135.493Z"/>
                    </mask>
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M135.493 56H56.5074H22H2C7.52285 56 12 51.5228 12 46V12C12 6.47715 16.4772 2 22 2H43.5999H148.4H170C175.523 2 180 6.47715 180 12V46C180 51.5228 184.477 56 190 56H170H135.493Z"
                          fill={selectedTab === 'app' ? 'white' : '#EEE'}/>
                    <path
                      d="M2 54C0.895431 54 0 54.8954 0 56C0 57.1046 0.895431 58 2 58V54ZM190 58C191.105 58 192 57.1046 192 56C192 54.8954 191.105 54 190 54V58ZM56.5074 58H135.493V54H56.5074V58ZM22 58H56.5074V54H22V58ZM2 58H22V54H2V58ZM10 46C10 50.4183 6.41828 54 2 54V58C8.62742 58 14 52.6274 14 46H10ZM10 12V46H14V12H10ZM22 0C15.3726 0 10 5.37258 10 12H14C14 7.58172 17.5817 4 22 4V0ZM43.5999 0H22V4H43.5999V0ZM148.4 0H43.5999V4H148.4V0ZM148.4 4H170V0H148.4V4ZM182 12C182 5.37258 176.627 0 170 0V4C174.418 4 178 7.58172 178 12H182ZM178 12V46H182V12H178ZM178 46C178 52.6274 183.373 58 190 58V54C185.582 54 182 50.4183 182 46H178ZM190 54H170V58H190V54ZM170 54H135.493V58H170V54Z"
                      fill="#DBDBDB" mask="url(#path-1-outside-1_1684_750)"/>
                    <rect y="56" width="192" height="2" fill="white"/>
                  </svg>
                  <div className={'absolute left-[50%] -translate-x-1/2 top-[50%] -translate-y-1/2 text-[#50555F] text-[20px] font-[pd-eb]'}>
                    앱으로 시작
                  </div>
                </div>
              </div>
              <div
                className={`${selectedTab === 'web' ? '' : 'hidden'} absolute left-0 bottom-0 w-[392px] h-[520px] bg-[#ffffff] border-[2px] border-[#dadada] shadow-[0_2px_7px_0_rgba(0,0,0,0.10)] rounded-[10px]`}>
                <div className={'flex justify-center items-center mt-[58px] text-[20px] text-[#50555F] font-[pd-eb]'}>
                  앱 설치 없이 바로 실행 가능합니다.
                </div>
                <div className={'flex justify-center items-center mt-[42px] select-none cursor-pointer'}>
                  <QRCodeCanvas value={webQrCodeValue} size={200} bgColor="#ffffff" fgColor="#000000" onClick={handleWebQrClick}/>
                </div>
                <div className={'flex justify-center items-center mt-[72px] cursor-pointer'}>
                  <div className={'border-[2px] rounded-[10px] w-[300px] h-[52px] relative flex items-center overflow-hidden whitespace-nowrap text-[#8C8C8C] text-[20px] font-[pd-b] pl-[8px]'}>
                    {webQrCodeValue}
                    <div className={'w-[100px] h-[52px] absolute right-0 top-[-2px] flex justify-center items-center bg-[#D9D9D9] rounded-r-[10px] text-[#303947] text-[20px] font-[pd-eb]'}
                         onClick={() => handleCopy(webQrCodeValue)}>
                      URL 복사
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`${selectedTab === 'app' ? '' : 'hidden'} absolute left-0 bottom-0 w-[392px] h-[520px] bg-[#ffffff] border-[2px] border-[#dadada] shadow-[0_2px_7px_0_rgba(0,0,0,0.10)] rounded-[10px]`}>
                <div className={'flex justify-center items-center mt-[37px] text-[24px] font-[pd-b]'}>
                  <span className={'text-[#355BDF]'}>[방법1]</span>&nbsp;<span className={'text-[#50555F]'}>수업 QR 찍기</span>
                </div>
                <div className={'flex justify-center items-center mt-[8px] select-none cursor-pointer'}>
                  <QRCodeCanvas value={appQrCodeValue} size={200} bgColor="#ffffff" fgColor="#000000" onClick={handleAppQrClick}/>
                </div>
                <div className={'px-[32px] mt-[20px]'}>
                  <div className={'bg-[#DBDBDB] h-[1px]'}></div>
                </div>
                <div className={'flex justify-center items-center mt-[20px] text-[24px] font-[pd-b]'}>
                  <span className={'text-[#355BDF]'}>[방법2]</span>&nbsp;<span className={'text-[#50555F]'}>수업을 전송하기</span>
                </div>
                <div className={'flex justify-center items-center mt-[8px] select-none cursor-pointer'}>
                  <div className={'w-[300px] h-[60px] bg-[#557cfc] rounded-full text-[28px] text-[#ffffff] font-[pd-eb] flex justify-center items-center'} onClick={() => sendClass()}>수업 전송하기</div>
                </div>
                <div className={'flex justify-center items-center mt-[12px] select-none cursor-pointer'} onClick={redirectToLmsLoginQr}>
                  <div className={'w-[300px] h-[60px] bg-[#FC5955] rounded-full text-[28px] text-[#ffffff] font-[pd-eb] flex justify-center items-center'}>로그인 QR 인쇄</div>
                </div>
              </div>
            </div>
            <div
              className={`${showLearningStatus ? '' : 'hidden'} w-[392px] h-[574px] bg-[#ffffff] border-[2px] border-[#dbdbdb] shadow-[0_2px_7px_0_rgba(0,0,0,0.10)] rounded-[10px] pt-[32px] px-[40px]`}>
              <div className={'text-[28px] font-[pd-eb] text-[#50555F]'}>
                접속/학습 현황
              </div>
              <div className={'mt-[28px] h-[468px] overflow-scroll'}>
                <div>
                  <div className={'flex items-center'}><span className={'text-[#50555f] text-[28px] font-[pd-eb]'}>완료</span>&nbsp;&nbsp;<span
                    className={'text-[#50555f] text-[20px] font-[pd-eb]'}>({completed.length})</span></div>
                  <div className={'grid grid-cols-[repeat(auto-fill,_minmax(96px,_1fr))] gap-x-[4px] gap-y-[16px] mt-[8px]'}>
                    {completed.length > 0 ? (
                      completed.map((value, index) => (
                        <div key={index} className={'w-[96px] h-[37px] flex justify-center items-center bg-[#EDFAF3] rounded-full text-[#5E6169] text-[14px] font-[pd-b]'}>
                          {value.number} {value.name}
                        </div>
                      ))
                    ) : null}
                  </div>
                  <div>
                    {completed.length === 0 ? (<p>학습이 완료된 인원이 없어요.</p>) : null}
                  </div>
                </div>
                <div className={'mt-[28px]'}>
                  <div className={'flex items-center'}><span className={'text-[#50555f] text-[28px] font-[pd-eb]'}>진행중</span>&nbsp;&nbsp;<span
                    className={'text-[#50555f] text-[20px] font-[pd-eb]'}>({inProgress.length})</span></div>
                  <div className={'grid grid-cols-[repeat(auto-fill,_minmax(96px,_1fr))] gap-x-[4px] gap-y-[16px] mt-[8px]'}>
                    {inProgress.length > 0 ? (
                      inProgress.map((value, index) => (
                        <div key={index} className={'w-[96px] h-[37px] flex justify-center items-center bg-[#FFF5E7] rounded-full text-[#5E6169] text-[14px] font-[pd-b]'}>
                          {value.number} {value.name}
                        </div>
                      ))
                    ) : null}
                  </div>
                  <div>
                    {inProgress.length === 0 ? (<p>학습 진행중인 인원이 없어요.</p>) : null}
                  </div>
                </div>
                <div className={'mt-[28px]'}>
                  <div className={'flex items-center'}><span className={'text-[#50555f] text-[28px] font-[pd-eb]'}>미진행</span>&nbsp;&nbsp;<span
                    className={'text-[#50555f] text-[20px] font-[pd-eb]'}>({notStarted.length})</span></div>
                  <div className={'grid grid-cols-[repeat(auto-fill,_minmax(96px,_1fr))] gap-x-[4px] gap-y-[16px] mt-[8px]'}>
                    {notStarted.length > 0 ? (
                      notStarted.map((value, index) => (
                        <div key={index} className={'w-[96px] h-[37px] flex justify-center items-center bg-[#F2F2F2] rounded-full text-[#5E6169] text-[14px] font-[pd-b]'}>
                          {value.number} {value.name}
                        </div>
                      ))
                    ) : null}
                  </div>
                  <div>
                    {notStarted.length === 0 ? (<p>학습하지 않은 인원이 없어요.</p>) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Learning;
