import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { ReactComponent as Logo } from '../../resources/images/arkmath_logo.svg';
import Class from './Class';

const Lessons = ({ startReload, teacher, isPaidVersion, school, lessonInfo, classes, setClasses, classDetails, setClassDetails }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const goToLessonDetails = (lessonStageId) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('lessonStageId', lessonStageId);
    const queryString = searchParams.toString();
    navigate(`/lesson/details?${queryString}`);
  };

  useEffect(() => {
    window.scrollTo(0, 0); // 페이지 로드 시 스크롤을 최상단으로 이동
  }, []);

  return (
    <div className={'pb-[200px] relative'}>
      <div className={'w-[68px] h-[68px] flex justify-center items-center border-[1px] border-[#dbdbdb] bg-[#ffffff] shadow-[0_2px_7px_0_rgba(0,0,0,0.10)] rounded-full absolute top-[20px] right-[20px] cursor-pointer select-none'} onClick={startReload}>
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
          <g clipPath="url(#clip0_1781_9186)">
            <path d="M33.2216 21.7353C32.9068 24.1352 31.9448 26.4042 30.4385 28.2989C28.9323 30.1936 26.9386 31.6425 24.6715 32.4903C22.4044 33.3381 19.9492 33.5528 17.5693 33.1112C15.1895 32.6697 12.9747 31.5887 11.1625 29.9841C9.3503 28.3795 8.00909 26.3119 7.28269 24.003C6.55628 21.6941 6.4721 19.231 7.03916 16.8779C7.60621 14.5248 8.80313 12.3704 10.5015 10.6459C12.1999 8.9213 14.3357 7.69159 16.6799 7.08861C23.1782 5.42194 29.9049 8.76694 32.3882 15.0003" stroke="#50555F" strokeWidth="3.33333" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M33.3333 6.6665V14.9998H25" stroke="#50555F" strokeWidth="3.33333" strokeLinecap="round" strokeLinejoin="round"/>
          </g>
          <defs>
            <clipPath id="clip0_1781_9186">
              <rect width="40" height="40" fill="white"/>
            </clipPath>
          </defs>
        </svg>
      </div>
      <div className={'h-[160px] flex justify-center items-center'}>
        <Logo/>
      </div>
      <div className={'flex justify-center'}>
        <Class lessonInfo={lessonInfo} classes={classes} setClasses={setClasses} classDetails={classDetails} setClassDetails={setClassDetails}/>
      </div>
      <div className={'flex justify-center font-[pd-m] text-[#50555F] mt-[30px] text-[20px]'}>
        {teacher?.name} | {school?.name}
      </div>
      <div className={'flex justify-center mt-[50px]'}>
        <div className={'rounded-[20px] border-[0.5px] border-[#dadada] shadow-[0_2px_7px_0_rgba(0,0,0,0.10)] bg-white py-[30px] px-[50px] flex items-center w-[66%]'}>
          <div className={'w-full flex justify-between'}>
            <div className={'text-[#50555F] font-[pd-b] text-[20px]'}>
              {lessonInfo?.title}
            </div>
            <div className={'flex items-center border border-[#D9D9D9] pl-[10px] pr-[20px] rounded-[50px]'}>
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
                <path
                  d="M1.5 2.75H6C6.79565 2.75 7.55871 3.06607 8.12132 3.62868C8.68393 4.19129 9 4.95435 9 5.75V16.25C9 15.6533 8.76295 15.081 8.34099 14.659C7.91903 14.2371 7.34674 14 6.75 14H1.5V2.75Z"
                  stroke="#50555F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path
                  d="M16.5 2.75H12C11.2044 2.75 10.4413 3.06607 9.87868 3.62868C9.31607 4.19129 9 4.95435 9 5.75V16.25C9 15.6533 9.23705 15.081 9.65901 14.659C10.081 14.2371 10.6533 14 11.25 14H16.5V2.75Z"
                  stroke="#50555F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
              <div className={'text-[#50555F] text-[20px] ml-[20px] font-[pd-b]'}>
                {classDetails?.lessonStages?.length ?? '0'}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={'flex justify-center mt-[50px]'}>
        <div className={'w-[66%] grid grid-flow-row auto-rows-max gap-4 grid-cols-[repeat(auto-fill,minmax(240px,1fr))] place-items-center'}>
          {classDetails?.lessonStages?.map((value, index) => (
            <div key={index} className={'cursor-pointer rounded-[20px] border-[0.5px] border-[#dadada] shadow-[0_2px_7px_0_rgba(0,0,0,0.10)] bg-white pt-[12px] px-[12px] w-[240px]'}
                 onClick={() => goToLessonDetails(value.lessonStageId)}>
              <div>
                <div className={'flex justify-between items-center'}>
                  <div
                    className={`font-[pd-b] text-[12px] px-[10px] py-[6px] rounded-[70px] ${value.completedLearningCount === 0 ? 'bg-[#F0F0F0] text-[#B9BCC2]' : value.completedLearningCount === classDetails?.studentCount ? 'bg-[#EEF6FF] text-[#7494FF]' : 'bg-[#E8FBF3] text-[#00C472]'} `}>
                    {value.completedLearningCount === 0 ? '미진행' : value.completedLearningCount === classDetails?.studentCount ? '완료' : '진행중'}</div>
                  <div
                    className={`font-[pd-b] text-[12px] ${value.completedLearningCount === 0 ? 'text-[#50555F]' : value.completedLearningCount === classDetails?.studentCount ? 'text-[#355BDF]' : 'text-[#50555F]'}`}>
                    {value.completedLearningCount}/{classDetails?.studentCount}명
                  </div>
                </div>
                <div className={'mt-[10px]'}>
                  <img src={`https://sla207651-sla207651.ktcdn.co.kr/stage_thumbnails/${value.stage.thumbnailImage}.png`} className={'rounded-[10px]'}
                       alt={(value.stage && value.stage.thumbnailImage) || 'thumbnailImage'}/>
                </div>
                <div className={'h-[100px] flex justify-center items-center font-[pd-b] text-[20px] text-[#50555F] text-center'}>
                  {value.stage.name}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Lessons;
