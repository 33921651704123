import PasswordUsageImage from '../../resources/images/password_usage.png';
import DraggableModal from '../../shared/components/DraggableModal';

const PasswordUsageModal = ({ showPasswordUsageModal, setShowPasswordUsageModal }) => {
  return (
    <DraggableModal show={showPasswordUsageModal} setShow={setShowPasswordUsageModal} headerHeight={126} title={'접속번호 인증하기 ON/OFF 기능'} titleStyle={'text-[#303947] text-[36px] font-[pd-eb]'}>
      <div className={'w-[936px] h-[560px] px-[80px]'}>
        <img src={PasswordUsageImage} alt={'PasswordUsage'} className={'rounded-[20px]'}/>
        <div className={'flex justify-center mt-[30px]'}>
          <div className={'text-[#303947] text-[30px] font-[pd-b]'}>
            ON으로 설정 시, 학생들이 잘못 로그인하는 것을 방지합니다.
          </div>
        </div>
      </div>
    </DraggableModal>
  );
};

export default PasswordUsageModal;
